.resultado {
    margin: 3rem 0rem 10rem 0;
}
.resultado *{
    font-family: Poppins, sans-serif !important;
}

.resultado h2 {
    font-size: 44px;
}

.resultado .textGreen {
    color: #11831C;
}

.resultado .col-izq-p {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
}
/*
.resultado .card {
    width: 33rem;
}*/
.resultado .card2 {
    width: max-content;
    padding: 0.8rem;
    border-radius: 20px;
}
.resultado .card2 .card-title{
    color: #8F9196;
    font-size: 24px;
}
.resultado .card2 .card-text{
    color: #54575C;
    font-weight: 700;
    font-size:56px;
    line-height: 65px;
    margin: 1rem 0;
}
.resultado th{
    white-space: nowrap;
}

.resultado td{
    white-space: nowrap;
    width: 100%;
}

.resultado .indicador{
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 80px;
    padding: 2px 5px;
    display: block;
}
.resultado .diferencia{
    color:#F24B27;
}
.resultado .ahorro{
    color:#11831C;
}
.resultado .modelo{
    font-weight: 600;
}

.resultado .new-h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    color: #333;

}
.resultado .container-top{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.resultado .parrafos {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    margin: 1.5rem 0 2.5rem;
}


.resultado .card label {
    font-size: 18px;
    line-height: 120%;
}

.resultado .btn {
    margin-top: 3rem;
    padding: 0.5rem 4rem;
}

.resultado .dudas {
    margin-top: 10rem;
}

.resultado .row {
    margin: auto;
    margin-top: 8rem;
}

.resultado .row.first {
    margin-top: 3rem;
}

.resultado .ctas .container .btn {
    margin-top: 1rem;
    height: 60px;
    width: 360px;
}

.resultado #calcular {
    background-color: transparent;
    border: none;
    outline: none;
}
.resultado .like p {
    width: 100%;
    text-align: center;
}
.resultado .like p::after {
    background-image: url('../svg/manitoLike.svg');
    background-size: 50px 40px;
    display: inline-block;
    content: "";
    width: 50px;
    height: 40px;
    background-repeat: no-repeat;
    position: absolute;
  
}


@media (max-width: 36em) {
    .resultado .row {
        margin-bottom: 3rem !important;
    }
    .resultado h2 {
        font-size: 24px !important;
    }

    .resultado h3 {
        font-size: 20px !important;
        margin-bottom: 1.5rem;
        font-weight: 500;
    }
    .resultado .like p {
        display: inline !important;
        text-align: center;
    }

    .resultado .like p::after {
        background-size: 20px 30px;
        width: 20px;
        height: 30px;
        bottom: 13%;
    }

    .resultado .row .ctn{
        padding: 0 1.5rem;
        margin-bottom: 3rem;
    }
    .resultado .row .ctn span{
        margin: 0.5rem 0;
    }
    .resultado .indicador{
        margin: 0.5rem 0;
        font-size: 0.75rem;
    }
    .resultado .text-right{
        margin: 0.5rem 0;
        font-size: 0.95rem;
    }
    .resultado .card{
        width: 100% !important;
    }
    .resultado .card-body{
        padding: 0.5rem;
    } 
    .resultado .btn {
        margin-top: 1rem;
    }
    .resultado .col-izq-p {
        font-size: 20px !important;
        line-height: 20px;
    }
    .resultado .container-top{
        text-align: center;
    }
    .resultado .container-top span{
        margin: 1rem 0;
    }
    .resultado td{
        white-space: normal;
        width: 100%;
        padding: 0;
    }
    .resultado .mobile .btn{
        padding: 0.5rem 2rem;
    }
    .resultado .parrafo2 .container-title{
     margin: 0;
     text-align: center;
     padding: 0.5rem;
    }
    .resultado .parrafo2 .btn, button#Solicitud_Contacto{
        padding: 0.5rem 1rem;
    }
}
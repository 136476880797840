html, body {
    padding: 0;
    margin: 0;
    background: #ffffff;
}

@font-face {
    font-family: "Poppins";
    src: url(../fonts/Poppins.ttf);
}

@font-face {
    font-family: "Roboto";
    src: url(../fonts/Roboto.ttf);
}

a {
    color: inherit;
    text-decoration: none;
}

h1 {
    font-size: 60px;
    line-height: 80px;
}

h2 {
    font-size: 36px;
    line-height: 120%;
}

h3 {
    font-size: 24px;
    line-height: 36px;
}

p {
    font-size: 20px;
    line-height: 30px;
}

small {
    font-size: 14px;
}

* {
    box-sizing: border-box;
    color: #333333;
    font-weight: 400;
    font-family: Roboto, sans-serif;
}

section {
    margin-top: 0px;
    position: relative;
    margin-bottom: 0rem;
    width: 100%;
}

.container {
    max-width: 1260px;
    margin-bottom: 5rem;
    padding: 0;
}

.container-padre {
    display: inline-flex;
    justify-content: center;
}

.bloque {
    justify-content: center;
    width: 50%;
}

    .bloque.desktop {
        display: flex;
    }

.card {
    box-shadow: 0px 100px 58px rgba(31, 41, 35, 0.00458735), 0px 41.346px 54.0995px rgba(31, 41, 35, 0.0109364), 0px 19.4023px 49.8576px rgba(31, 41, 35, 0.0211526), 0px 7.32773px 44.5609px rgba(31, 41, 35, 0.0458371), 0px 1.03284px 36.9139px rgba(31, 41, 35, 0.0757482), 0px -1.12437px 24.2747px rgba(31, 41, 35, 0.09);
    border-radius: 4px;
    width: 100%;
}

    .card.formulario {
        max-width: 528px;
        justify-content: center;
        padding: 0;
    }

    .card .card-header {
        background-color: #ffffff !important;
        border: none;
    }

.mt-30 {
    margin-top: 30px;
}
.montoApv{
    font-size: 35px;
}

.mobile .container-title {
    height: 270px;
}

.container-title h1 {
    font-size: 60px;
}

.container-title p {
    font-family: Poppins, sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    color: #54575C;
}

.txtDesktop {
    margin: auto;
}

.green {
    color: #72b500 !important;
}

.blue {
    color: #0b7ac7 !important;
}


.circle {
    padding: 3px 15px;
    color: #ffffff;
    border-radius: 50px;
    font-size: 2.5rem;
}

.green-circle {
    background-color: #8EC045;
}

.orange-circle {
    background-color: #F7931E;
}

.mobile {
    display: none !important;
}

.green-tabla {
    background-color: #72b500 !important;
    color: #ffffff !important;
}

.blue-tabla {
    background-color: #0B7AC7 !important;
    color: #ffffff !important;
}

.disclaimer-modal {
    font-family: 'Roboto';
    font-size: 12px;
    text-align: justify;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    color: #54575C !important;
}

/**********************Formulario**********************/

.form {
    margin-top: 3rem;
}

.form-control {
    display: block;
    line-height: 2em;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    font-size: medium;
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
}

    .form-control:focus {
        border: 1px solid #2c7ac9;
    }

.control-label {
    display: block;
    position: absolute;
    opacity: 0;
    bottom: 1.9rem;
    color: #54575c;
    transition: 0.2s ease-in-out transform;
    font-size: 10px;
}

.form-control:placeholder-shown + .control-label {
    visibility: hidden;
    z-index: -1;
    transition: 0.2s ease-in-out;
}

.form-control:not(:placeholder-shown) + .control-label, .form-control:focus:not(:placeholder-shown) + .control-label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translate(5px, -7px);
    transition: 0.2s ease-in-out transform;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #54575c;
}

.form-group {
    margin: 12px;
}

    .form-group input, .form-group select {
        border: #999999 1px solid;
        border-radius: 4px;
        width: 100%;
    }

    .form-group small {
        margin: 0;
    }

.select label {
    width: 100%;
    font-size: 14px;
}

.btn {
    background: #82388b;
    border-radius: 50px;
    color: #ffffff;
    width: fit-content;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: normal;
}

    .btn:hover {
        color: #ffffff;
    }

.whiteBtn {
    background: #ffffff !important;
    border: 1px solid #0B7AC7;
    color: #0B7AC7;
}

    .whiteBtn:hover {
        color: #0B7AC7 !important;
    }

.blueBtn {
    background: #0b7ac7 !important;
}

.greenBtn {
    background: #66A30A !important;
}

.terminosYcondiciones {
    color: #5b5b5b;
}

.form-check {
    margin: 1rem;
    white-space: break-spaces;
}

.medio {
    display: flex !important;
}

small {
    color: #6c757d;
}

    small.is-invalid {
        color: #ed1c24 !important;
    }

    small .is-valid {
        color: #1bb934;
    }

input[type="checkbox"] + label {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.volver {
    margin-top: 1rem;
    color: #0B7AC7;
    font-size: 18px;
    line-height: 20px;
}

    .volver:hover {
        text-decoration: none;
    }

    .volver img {
        margin: 0 !important;
    }
/**********************Fin formulario**********************/

/**********************Inicio Footer**********************/

.footer {
    width: 100% !important;
    background: #0c4c72;
    position: relative;
    bottom: 0;
}
.container-footer {
    padding: 4rem 8rem;
}

.footer p {
    margin: 1rem 0;
    color:#ffffff;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: justify;
}
.footer p a {
    color: #66A30A !important;
}
.footer span {
    color: #66A30A;
    display: flex;
    justify-content: center;
}
.footer br{
    display: none;
}

/**********************Fin Footer**********************/

p.card-text {
    text-align: center;
}

@media (max-width: 82em) {
    h1 {
        font-size: 50px !important;
        line-height: 70px !important;
    }

    p {
        font-size: 18px !important;
    }
    .footer .container-footer {
        padding: 2rem;
    }
    .footer span{
        justify-content: start;
    }
    .footer br{
        display: flex;
    }
}

@media (max-width: 62em) {
    .medio {
        display: none !important;
    }
}

@media (max-width: 48em) {
    .mobile {
        display: flex !important;
        padding: 0;
    }

    .desktop {
        display: none !important;
    }

    .container-padre {
        display: block;
    }

    .container-title {
        padding: 2rem;
    }

    .bloque {
        display: flex;
    }

    .card {
        width: 100%;
    }

    section .row {
        margin: 0 !important;
    }

    .container-title h1 {
        font-size: 2rem !important;
    }

    .bloque {
        width: 100%;
    }
}

@media (max-width: 36em) {
    body {
        width: 100% !important;
    }

    h1 {
        font-size: 36px !important;
        line-height: 47px !important;
    }

    h2 {
        font-size: 20px !important;
        line-height: 120%;
    }

    h3 {
        font-size: 14px !important;
        line-height: 21px;
    }

    p {
        font-size: 14px !important;
        line-height: 21px;
    }
    p.card-text {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    small {
        font-size: 12px !important;
    }

    .form {
        margin-top: 1rem;
        padding: 0;
    }

    .volver img {
        margin: 0 !important;
    }

    .card.formulario {
        max-width: 90%;
    }
}

@media (max-width: 23.4em) {
    .label-mobile-error {
        transform: translate(5px, -25px) !important;
    }
}

.calculadora .abrirApv img {
    width: 5%;
}

.w-94{
    width: 94%;
}

.calculadora .container-title {
    margin-right: 5%;
}

    .calculadora .container-title h1 {
        margin: 1.5rem;
        font-weight: 500;
        margin-left: 0;
    }

.containerForm {
    width: fit-content !important;
}

.wizardForm .row {
    position: relative;
    flex-wrap: nowrap;
    width: fit-content;
}

.wizardForm .col-md-1 {
    display: flex;
    justify-content: center;
}

.wizardForm img {
    margin-left: 1rem;
}

.wizardForm .card-body {
    margin-top: 1.2rem;
}

.modal-wizard {
    color: #0B7AC7;
    cursor: pointer;
}

.wizardForm .card-body p {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    margin-left: 14px;
}

.wizardForm form input, .wizardForm form select {
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 52.88px;
}

.wizardForm form .form-check-label input {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    position: relative;
}

.wizardForm form .form-check-input {
    width: 18px;
}

.wizardForm form small {
    margin-top: 5px;
    color: #54575C;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.wizardForm form button {
    margin: 1rem;
    padding: 0.4rem 3rem;
    margin-bottom: 2rem;
}

.regimenes {
    margin-top: 10rem;
}

    .regimenes h2 {
        font-size: 36px;
        margin-bottom: 5rem;
    }

    .regimenes .card {
        max-height: 488px;
        height: 400px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .regimenes .card-header {
        font-weight: 500;
        font-size: 32px;
        line-height: 24px;
        color: #333333;
        padding: 2rem;
        border: none;
    }

    .regimenes .card-text {
        font-family: Poppins, sans-serif;
        font-size: 20px;
        line-height: 40px;
    }

    .regimenes .card-link {
        color: #0B7AC7;
        font-size: 18px;
        line-height: 24px;
    }

@media (max-width: 82em) {

}

@media (max-width: 48em) {
    .txtMobileShape {
        color: #FFFFFF;
        z-index: 99;
        width: 50%;
        margin-left: 13%;
        margin-top: 18%;
        margin-bottom: 0rem;
    }

    .background {
        position: absolute;
        top: 64px;
        right: 0.3px;
        left: 0.3px;
        width: 100%;
    }

    .form {
        margin-top: 5%;
    }
}

@media (max-width: 36em) {
    .calculadora .container-title h1 {
        margin: 0;
    }

    .wizardForm form input {
        width: 100%;
    }

    .wizardForm .card-body {
        padding: 0.25rem;
    }

    .calculadora .header {
        z-index: 1;
    }

    .background {
        top: -50px;
    }

    .regimenes h2 {
        margin-bottom: 2rem;
    }

    .regimenes .card {
        height: fit-content;
        margin-bottom: 1rem;
    }

    .regimenes .card-header {
        font-size: 24px;
        line-height: 24px;
    }

    .regimenes .card-body {
        padding-top: 0;
    }

    .regimenes .card-text {
        font-size: 14px;
        line-height: 24px;
    }

    .regimenes .card-link {
        font-size: 16px;
    }

    .wizardForm form .form-check-label {
        padding: 0;
    }

    .txtMobileShape {
        margin-left: 2rem !important;
        margin-top: -1%;
    }
}
.bg-green {
    background-color: #80BA00 !important;
}
.bg-dark {
    background-color: #000000 !important;
}
.align {
	margin-top: 8px;
	margin-left: 96px;
	margin-bottom: 8px;
}
header {
    background-color: black;
    color: white;
    height: 90px;
  }
  
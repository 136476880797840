.solicitud{
    margin-bottom: 5rem;
}
.solicitud .txt {
    width: 700px;
    margin-top: 2rem;
}

.nu-header {
    font-size: x-large;
    margin-bottom: -30px;
}
    .solicitud .txt h4 {
        font-size: 32px;
        line-height: 3px;
        margin: 2.5rem;
    }

    .solicitud .txt p {
        font-family: Poppins !important;
        font-size: 20px !important;
        line-height: 150% !important;
    }

.solicitud .container-block {
    text-align: center;
}

    .solicitud .container-block img {
        margin-top: 5rem;
        margin-bottom: 3rem;
    }

@media (max-width: 36em) {
    .solicitud .txt {
        width: 100%;
        margin-top: 0;
    }

        .solicitud .txt h4 {
            font-size: 20px;
            margin: 0;
            margin-bottom: 2rem;
        }

        .solicitud .txt p {
            font-size: 16px !important;
        }
        .solicitud .container-title{
            padding: 1rem;
            margin-left: 0 !important;
        }
}
